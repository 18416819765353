@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");
@import url("https://use.typekit.net/zqz5elg.css");

/* font setting and scrollbar desable */
body {
  height: 100%;
  margin: 0;
  font-family: "proxima-nova" !important;
}

#root {
  height: 100%;
}

code {
  font-family: "proxima-nova" !important;
}

body::-webkit-scrollbar {
  display: none;
}

/* ------------------------------------------- */

#companyProfileWidget {
  max-height: 390px !important;
}

/***************************************************************
** every ant.design select dropdown 
*/
.ant-select-dropdown ::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}

.ant-select-dropdown ::-webkit-scrollbar-track {
  border-radius: 20px;
  /* background: #eaf2fa; */
}

.ant-select-dropdown ::-webkit-scrollbar-thumb {
  border-radius: 20px;
  /* background: #2178ff; */
}

.ant-select-dropdown {
  scrollbar-width: thin;
}

/* ------------------------------------------- */

/***************************************************************
** when the class is setted, it disables the recaptcha in that page 
*/
.body-disableRecaptcha .grecaptcha-badge {
  visibility: hidden !important;
}

/* ------------------------------------------- */

/***************************************************************
/* ++++++ idk what uses this ++++++++++++ */
.ant-pro-core-label-tip-icon {
  display: flex;
  align-items: center;
}

/* ------------------------------------------- */

/***************************************************************
** sider Menu css overwrite 
** customizing the sider layout
*/
.ant-pro-menu-item {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
}

.ant-menu-item-selected .ant-pro-menu-item-title {
  font-weight: 600;
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item {
  height: 56px;
}

.c-antd-br-8 {
  border-radius: 8px;
}

.ant-message .ant-message-notice > div {
  border-radius: 8px;
}

.customCollapsePanel {
  .ant-collapse-content-box {
    padding: 12px;
    border-top: 1px solid #f1f8e5;
  }
}

body > iframe[style*='2147483647']{
  display:none ;
}